import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import RepoCard from 'components/RepoCard'
import { IntlProvider } from 'contexts/intl/IntlContext'

const queryImage = graphql`
  query {
    site {
      siteMetadata {
        url
      }
    }
    file(relativePath: { eq: "joshua-reddekopp-syymxsdnj54-unsplash.jpg" }) {
      absolutePath
      accessTime
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
          src
        }
      }
      name
    }
    allMarkdownRemark(
      filter: { frontmatter: { path: { eq: "stories-post" } } }
      sort: { frontmatter: { publishedAt: DESC } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            topics
            description
            publishedAt(formatString: "DD MMMM YYYY")
            featuredPost
          }
        }
      }
    }
  }
`

const RecommendedStories = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'recommended.stories.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.recommended' })}
        />
        <section className='section is-size-4-desktop is-size-5-touch'>
          <h1 className='title has-text-centered has-text-light'>
            <FormattedMessage id='recommended.stories.title' />
          </h1>
          <h6 className='subtitle has-text-centered has-text-light'>
            <FormattedMessage id='recommended.stories.subtitle' />
          </h6>
          <div className='container'>
            <hr />
            <div className='columns'>
              <div className='column is-half is-offset-one-quarter'>
                <StaticQuery
                  query={queryImage}
                  render={data => {
                    if (data.errors) {
                      throw data.errors
                    }
                    const posts = data.allMarkdownRemark.edges.map(({ node }) => node)
                    const postsLocale = posts

                    return (
                      <>
                        {postsLocale.map(post => {
                          let topics = post.frontmatter.topics.map(node => node) //.slice(0, 3)

                          return (
                            <RepoCard
                              key={post.fields.slug}
                              title={post.frontmatter.title}
                              subtitle={post.frontmatter.description.substring(0, 100)}
                              link={`${post.fields.slug}`}
                              image={
                                <img
                                  style={{ borderRadius: '0.8em', border: '1em' }}
                                  src={data.file.childImageSharp.fluid.src}
                                  alt={data.site.siteMetadata.url + post.fields.slug}
                                  layout='constrained'
                                  aspectRatio={data.file.childImageSharp.fluid.aspectRatio}
                                  transformOptions={{
                                    fit: 'cover',
                                    cropFocus: 'attention',
                                  }}
                                  width='200px'
                                  backgroundColor='white'
                                />
                              }
                              topics={topics}
                            />
                          )
                        })}
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <p className='has-text-centered'>
              <FormattedMessage id='checkOut' />{' '}
              <Link to={getPath('blogs')}>
                <FormattedMessage id='stack.posts' />
              </Link>
              {'.'}
            </p>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
)

RecommendedStories.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default RecommendedStories
